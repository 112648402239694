<template>
    <b-container fluid>
         <card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('tpm_report.training_course_evaluation_report') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                            <b-form @submit.prevent="handleSubmit(circularPublicationAdd)" @reset.prevent="reset" >
                                <b-row>
                                    <b-col lg="6" sm="6">
                                        <ValidationProvider name="Circular Memo No"  vid="circular_memo_no" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="circular_memo_no"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="search.circular_memo_no"
                                                    :options="circularList"
                                                    id="circular_memo_no"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12"><b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button></b-col>
                                </b-row>
                            </b-form>
                        </ValidationObserver>
                    </b-col>
              </b-row>
            </template>
        </card>
        <b-row>
          <b-col md="12">
            <b-overlay>
              <b-row v-if='search.details.length > 0'>
                <b-col md="12">
                   <card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('tpm_report.training_course_evaluation_report') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button class="btn_add_new" @click="pdfExport">
                        <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                      <b-overlay>
                        <b-row mt-5>
                          <b-col md="12" class="table-responsive">
                            <div style="border: 2px solid;margin:10px;padding:10px">
                              <b-row>
                                <b-col>
                                  <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                                    <template v-slot:projectNameSlot>
                                      {{ }}
                                    </template>
                                    {{ $t('tpm_report.training_course_evaluation_report') }}
                                  </list-report-head>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                    <div class="text-center">
                                        <table style="width:100%;color:black;">
                                            <tr v-if="search.circular_memo_no">
                                                <td align="right" style="width:45%">{{ $t('elearning_iabm.circular_memo_no') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ search.circular_memo_no }}</td>
                                            </tr>
                                            <!-- <tr v-if="search.training_type_id">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.training_type') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_type_bn : search.training_type }}</td>
                                            </tr>
                                            <tr v-if="search.training_category_id">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.training_category') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_category_bn : search.training_category }}</td>
                                            </tr>
                                            <tr v-if="search.training_title_id">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.training_title') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_title_bn : search.training_title }}</td>
                                            </tr> -->
                                        </table>
                                    </div>
                                </b-col>
                              </b-row>
                              <b-row>
                                <div class="col-md-12" id="form">
                                  <div class="col-12">
                                    <b-table-simple hover small caption-top responsive border v-if="search.details.length > 0">
                                      <b-thead class="thead">
                                        <b-tr>
                                          <b-th style="vertical-align: middle;width:80px" class="text-center">{{$t('globalTrans.sl_no')}}</b-th>
                                          <b-th style="vertical-align: middle" class="text-center">{{$t('globalTrans.name')}}</b-th>
                                          <b-th style="vertical-align: middle" class="text-center">{{$t('globalTrans.mobile')}}</b-th>
                                          <b-th style="vertical-align: middle" class="text-center">{{$t('elearning_tim.posting_office')}}</b-th>
                                          <b-th style="vertical-align: middle" class="text-right">{{$t('globalTrans.mark')}}</b-th>
                                          <b-th class="text-right">
                                            {{$t('tpm_report.pre_evaluation')}}
                                          </b-th>
                                          <b-th style="vertical-align: middle" class="text-right">{{$t('globalTrans.mark')}}</b-th>
                                          <b-th class="text-right">
                                            {{$t('tpm_report.post_evaluation')}}
                                          </b-th>
                                        </b-tr>
                                      </b-thead>
                                      <b-tr v-for="(course, index) in search.details" :key="index">
                                        <b-td class="text-center">{{$n(index+1)}}</b-td>
                                        <b-td class="text-center">
                                          {{ ($i18n.locale==='bn') ? course.name_bn : course.name }}
                                        </b-td>
                                        <b-td class="text-center">
                                          {{ course.mobile }}
                                        </b-td>
                                        <b-td class="text-left">
                                          <slot v-if="course.training_status === 1">
                                            {{ ($i18n.locale==='bn') ? course.designation_name_bn : course.designation_name }}
                                          </slot>
                                          <slot v-else>
                                            {{ course.designation }}
                                          </slot>
                                          {{ (($i18n.locale==='bn') ? course.office_name_bn : course.office_name) + ', ' + (($i18n.locale==='bn') ? course.org_name_bn : course.org_name) }}
                                        </b-td>
                                        <b-td class="text-right">
                                          {{ course.total_mark_pre }}
                                        </b-td>
                                        <b-td class="text-right">
                                          {{ course.obtained_mark }}
                                        </b-td>
                                        <b-td class="text-right">
                                          {{ course.total_mark_post }}
                                        </b-td>
                                        <b-td class="text-right">
                                          {{ course.obtained_mark_post }}
                                        </b-td>
                                      </b-tr>
                                    </b-table-simple>
                                    <template v-if="search.details.length === 0">
                                        <br/>
                                        <br/>
                                        <h5 class="text-center text-danger" style="height: 80px">{{$t('globalTrans.noDataFound')}}</h5>
                                    </template>
                                  </div>
                                </div>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                  </card>
                </b-col>
              </b-row>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
          <!-- <pre>{{search}}</pre> -->
        </b-row>
    </b-container>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { manualCourseEvaluation, circularPublicationList, circularPublication } from '../../api/routes'
// import { trainingCourseSummaryReport, manualCourseEvaluation, circularPublication } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ListReportHead
    },
    created () {
    },
    data () {
        return {
          saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          search: {
            circular_memo_no: 0,
            training_type_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            course_id: 0,
            details: []
          },
          trainingCategoryList: [],
          trainingTitleList: [],
          courseList: [],
          circularList: [],
          circularLoading: false,
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          datas: [],
          allreadyApplicant: [],
          courses: [],
          loading: false,
          showData: false
        }
    },
    mounted () {
        flatpickr('.fromDate', {})
        this.getCircularList()
    },
    computed: {
        trainingTypeList: function () {
            return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        },
        i18 () {
            return this.$i18n.locale
        }
    },
    watch: {
        'search.circular_memo_no': function (newValue) {
            this.courseList = this.getCourseList(newValue)
        },
        'search.training_type_id': function (newValue) {
            this.trainingCategoryList = this.getCategoryList(newValue)
        },
        'search.training_category_id': function (newValue) {
            this.trainingTitleList = this.getTrainingTitleList(newValue)
        }
    },
    methods: {
        async getCircularList () {
          this.circularLoading = true
          const serchData = {
            org_id: this.$store.state.dataFilters.orgId
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'tpm_training_calendar', serchData)
          if (!result.success) {
          } else {
            const listObject = result.data
            const tmpList = listObject.map((obj, index) => {
              if (this.$i18n.locale === 'bn') {
                return { value: obj.circular_memo_no, text: obj.circular_memo_no }
              } else {
                return { value: obj.circular_memo_no, text: obj.circular_memo_no }
              }
            })
            this.circularList = tmpList
          }
          this.circularLoading = false
      },
      pdfExport () {
          const reportTitle = this.$t('tpm_report.training_course_evaluation_report')
          ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this, this.search, this.courses)
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      getCourseList (circularMemoNo) {
        const courseList = this.$store.state.TrainingElearning.commonObj.courseList
        if (circularMemoNo) {
          return courseList.filter(item => item.circular_memo_no === String(circularMemoNo))
        }
        return courseList
      },
      async circularPublicationAdd () {
        if (this.search.circular_memo_no) {
            this.load = true
            const params = {
              circular_memo_no: this.search.circular_memo_no,
              table: 'tpm_training_calendar'
            }
            const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
            if (!result.success) {
            this.load = false
            } else {
            this.courses = result.courses
            if (this.search.course_id) {
                this.courses = result.courses.filter(item => item.id === parseInt(this.search.course_id))
            }
            this.searchData(this.search.circular_memo_no)
            }
        } else {
          this.allreadyApplicant = []
          this.search.details = []
        }
      },
      async searchData () {
        this.loading = true
        this.showData = true
        this.getCustomDataSearch()
        const searchData = this.search
        searchData.details = []
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, manualCourseEvaluation, searchData)
        if (!result.success) {
          this.allreadyApplicant = []
          this.search.details = []
        } else {
          this.allreadyApplicant = result.allreadyApplicant
          if (this.allreadyApplicant.length > 0) {
            const datas = result.data.map(item => {
              const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
              const desigData = {}
              if (typeof desigObj !== 'undefined') {
                desigData.designation_name = desigObj.text_en
                desigData.designation_name_bn = desigObj.text_bn
              } else {
                desigData.designation_name = ''
                desigData.designation_name_bn = ''
              }
              const orgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === parseInt(item.professional_org_id))
              const orgData = {}
              if (typeof orgObj !== 'undefined') {
                orgData.org_name = orgObj.text_en
                orgData.org_name_bn = orgObj.text_bn
              } else {
                orgData.org_name = ''
                orgData.org_name_bn = ''
              }
              const officeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(item.professional_office_id))
              const officeData = {}
              if (typeof officeObj !== 'undefined') {
                officeData.office_name = officeObj.text_en
                officeData.office_name_bn = officeObj.text_bn
              } else {
                officeData.office_name = ''
                officeData.office_name_bn = ''
              }
              const allreadyCourseObj = this.allreadyApplicant.find(allready => allready.training_application_id === parseInt(item.id) && allready.type === 'pre')
              const allreadyCourseObjpost = this.allreadyApplicant.find(allready => allready.training_application_id === parseInt(item.id) && allready.type === 'post')
              const trData = {}
              trData.total_mark_pre = typeof allreadyCourseObj !== 'undefined' ? allreadyCourseObj.total_mark : 0
              trData.obtained_mark = typeof allreadyCourseObj !== 'undefined' ? allreadyCourseObj.obtained_mark : 0
              trData.total_mark_post = typeof allreadyCourseObjpost !== 'undefined' ? allreadyCourseObjpost.total_mark : 0
              trData.obtained_mark_post = typeof allreadyCourseObjpost !== 'undefined' ? allreadyCourseObjpost.obtained_mark : 0
              return Object.assign({}, item, desigData, orgData, officeData, trData)
            })
            this.search.details = datas
          }
        }
      },
      getCustomDataSearch () {
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(this.search.training_type_id))
        if (typeof trainingTypeObj !== 'undefined') {
            this.search.training_type = trainingTypeObj.text_en
            this.search.training_type_bn = trainingTypeObj.text_bn
        } else {
            this.search.training_type = ''
            this.search.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(this.search.training_category_id))
        if (typeof trainingCategoryObj !== 'undefined') {
            this.search.training_category = trainingCategoryObj.text_en
            this.search.training_category_bn = trainingCategoryObj.text_bn
        } else {
            this.search.training_category = ''
            this.search.training_category_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(this.search.training_title_id))
        if (typeof trainingTitleObj !== 'undefined') {
            this.search.training_title = trainingTitleObj.text_en
            this.search.training_title_bn = trainingTitleObj.text_bn
        } else {
            this.search.training_title = ''
            this.search.training_title_bn = ''
        }
      }
    }
}
</script>
